
import * as React from 'react';
import { useState, useEffect } from 'react';


import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, background, useToast, Card, CardBody,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";


import { BiAddToQueue } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { validateLocaleAndSetLanguage } from "typescript";

import NamespaceModal from "./namespace-modal";
import AssembliesModal from "./assemblies-modal";
import { IoRemove } from 'react-icons/io5';

export interface INamespace {
    namespace: string;
    is_auto: boolean;
}
const defaultWorkflow: INamespace[] = [
    {
        namespace: "Test.Package",
        is_auto: true,
    }
];
export interface IAssembly {
    code_id: string;
    assembly: string;
}
const defaultAssembly: IAssembly[] = [
    {
        code_id: "test",
        assembly: "test-assembly"
    }
]
export interface IAppliedAssembly {
    assembly: string;
    is_auto: boolean;
}
const defaultAppliedAssembly: IAppliedAssembly[] = [
    {
        assembly: "test",
        is_auto: false,
    }
]
export interface IDefaultNamespaces {
    name_space: string;
    is_default: boolean;
}
const defaultDefaultNamespaces: IDefaultNamespaces[] = [
    {
        name_space: "test",
        is_default: true,
    }
]
export interface IDefaultAssemblies {
    assembly_name: string;
    is_default: boolean;
}
const defaultAssemblies: IDefaultAssemblies[] = [
    {
        assembly_name: "test",
        is_default: true,
    }
]
export default function ShowAssembliesNamespaces(props: any) {


    const [search, setSearch] = useState("");
    const [rows, setRows] = useState<INamespace[]>(defaultWorkflow);
    const [rows2, setRows2] = useState<INamespace[]>(defaultWorkflow);
    const [rows3, setRows3] = useState<IAssembly[]>(defaultAssembly);
    const [rows4, setRows4] = useState<IAppliedAssembly[]>(defaultAppliedAssembly);
    const [default_namespaces, setDefaultNamespaces] = useState<IDefaultNamespaces[]>(defaultDefaultNamespaces);
    const [default_assemblies, setDefaultAssemblies] = useState<IDefaultAssemblies[]>(defaultAssemblies);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState('400px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const webHelpers = useWebHelper();

    const { isOpen, onOpen, onClose } = useDisclosure();

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function searchWorkflows() {
        if (props.code_id !== null && props.code_id !== "") {
            webHelpers.GetAsync('/api/code/' + props.code_id + "/namespaces/applied", 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    //@ts-ignore
                    setRows(Object.values(data));
                    //setMaxPages(data.maximum_pages)
                }
            })
            //get namespaces for code
            webHelpers.GetAsync('/api/code/' + props.code_id + '/namespaces', 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    //@ts-ignore
                    setRows2(Object.values(data));
                    //setMaxPages(data.maximum_pages)
                }
            })
            webHelpers.GetAsync('/api/code/' + props.code_id + '/assemblies', 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows3(Object.values(data));
                }
            })
            webHelpers.GetAsync('/api/code/' + props.code_id + '/assemblies/applied', 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows4(Object.values(data));
                }
            })
        }
        else {
            return (
                toast({
                    position: "bottom-left",
                    title: `Could not fetch namespaces, code hasn't been initialised yet! Code is currently null`,
                    status: "error",
                    isClosable: true,
                })
            )
        }
        webHelpers.GetAsync('/api/code/config/namespaces', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                setDefaultNamespaces(Object.values(data));
            }
        })
        webHelpers.GetAsync('/api/code/config/assemblies', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                setDefaultAssemblies(Object.values(data));
            }
        })

    }
    const handleDeleteNamespace = (event: INamespace) => {
        webHelpers.DeleteAsync("/api/code/" + props.code_id + "/namespace/" + event.namespace, "helios-api", {}).then((data: any) => {
            console.log("sucessfully deleted ", event.namespace);
            searchWorkflows();
        })
        searchWorkflows();
    }
    const handleDeleteAssembly = (event: IAppliedAssembly) => {
        webHelpers.DeleteAsync("/api/code/" + props.code_id + "/assembly/" + event.assembly, "helios-api", {}).then((data: any) => {
            searchWorkflows();
        }).catch((error) => {
            console.log("error deleting namespace");
            //this API call doesn't return anything, even when it works so despite the fact this is printed to console, it very much works
            searchWorkflows();
        })
        searchWorkflows();
    }
    useEffect(() => {
        searchWorkflows();
    }, [page]);

    useEffect(() => {
        const requestTimeout = setTimeout(() => {
            searchWorkflows()
        }, 500);
        return () => clearTimeout(requestTimeout);
    }, [search, rowsPerPage]);

    const addNewNamespace = (payload: string) => {
        let payloadToSend = {
            namespace: payload
        }
        webHelpers.PutAsync('/api/code/' + props.code_id + "/namespace/", 'helios-api', payloadToSend).then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to add new namespace', { 'variant': 'error' })
            }
            else {
                searchWorkflows();
            }
        })
    }
    const addNewAssembly = (payload: string) => {
        let payloadToSend = {
            assembly_name: payload
        }
        webHelpers.PutAsync('/api/code/' + props.code_id + "/assembly/", 'helios-api', payloadToSend).then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to add new nameserver', { 'variant': 'error' })
            }
            else {
                searchWorkflows();
            }
        })
    }
    return (
        <>
            <Button onClick={onOpen} marginRight={"10px"}  bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} >
                Open Assemblies
            </Button>
            {rows !== defaultWorkflow ? (
                <Drawer isOpen={isOpen} onClose={onClose} size={"sm"}>
                    <DrawerOverlay />
                    <DrawerContent className="table-container" bg={"#2F3129"} padding={"10px"}  overflowY={"scroll"} overflowX={"clip"}>
                        <DrawerCloseButton color={"white"}/>

                        <Box className="test-box">
                            <Text fontSize={"xl"} color={"white"}>Applied Assemblies</Text>

                            <Text as={"i"} color={"white"} fontSize={"md"} marginBottom={"10px"}>Enabled by Default</Text>
                            <Flex direction={"column"}>
                                {rows4.map((workflow: IAppliedAssembly) => (
                                    (workflow.is_auto ?
                                        <>
                                            <Card w={"-webkit-fit-content"} size={"sm"} marginBottom={"10px"} bg={"AbleBlue"} color={"white"}>
                                                <CardBody>
                                                    <Text>{workflow.assembly}</Text>
                                                </CardBody>
                                            </Card>
                                        </>
                                        :
                                        <p>

                                        </p>
                                    )
                                ))}
                            </Flex>
                            {rows4.filter((each) => each.is_auto === false).length > 0 &&
                                <Text color={"white"} fontSize={"md"} >
                                    Selected by User
                                </Text>
                            }
                            {rows4.map((workflow: IAppliedAssembly) => (
                                (workflow.is_auto ?
                                    <p></p>
                                    :
                                    <>
                                        <Card w={"-webkit-fit-content"} marginBottom={"10px"} size={"sm"} bg={"AbleBlue"} color={"white"}>
                                            <CardBody>
                                                <Text>{workflow.assembly}</Text>
                                                <Button color={"AbleYellow"} _hover={{ bg: "AbleYellow", textColor: "white" }}
                                                    leftIcon={<IoRemove />} bg={"AbleBlue"} onClick={() => handleDeleteAssembly(workflow)}>Delete</Button>
                                            </CardBody>
                                        </Card>
                                    </>
                                )))}
                            <AssembliesModal cb={addNewAssembly} />
                            <Text fontSize={"xl"} color={"white"} marginTop={"10px"}>Applied Namespaces</Text>
                            <Text as={"i"} color={"white"} marginBottom={"10px"} fontSize={"md"} >Enabled by Default</Text>
                            {rows.map((workflow: INamespace) => (
                                (workflow.is_auto ?
                                    <>
                                        <Card w={"-webkit-fit-content"} marginBottom={"10px"} size={"sm"} bg={"AbleBlue"} color={"white"}>
                                            <CardBody>
                                                <Text>{workflow.namespace}</Text>
                                            </CardBody>
                                        </Card>
                                    </>
                                    : <p></p>)

                            ))}
                            {rows.filter((workflow) => workflow.is_auto === false).length > 0 &&
                                <Text color={"white"} fontSize={"md"} >
                                    <br></br>
                                    Selected by User
                                </Text>
                            }
                            {rows.map((workflow: INamespace) => (
                                (workflow.is_auto ?
                                    <p></p>
                                    :
                                    <>
                                        <Card w={"-webkit-fit-content"} marginBottom={"10px"} size={"sm"} bg={"AbleBlue"} color={"white"}>
                                            <CardBody>
                                                <Text>{workflow.namespace}</Text>
                                                <Button color={"AbleYellow"} _hover={{ bg: "AbleYellow", textColor: "white" }}
                                                    leftIcon={<IoRemove />} onClick={() => handleDeleteNamespace(workflow)}>Delete</Button>
                                            </CardBody>
                                        </Card>
                                    </>
                                )))}
                            <NamespaceModal className="add-button" cb={addNewNamespace} />

                        </Box>
                    </DrawerContent>
                </Drawer>

            ) : (
                <></>
            )
            }
        </>
    );
}
